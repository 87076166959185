import React from 'react';
import styled from 'styled-components';
import { TemplatedLazyImage } from '../../../../components/image';

const Wrapper = styled.div`
    width: 100%;
    border-radius: 0.75rem;
    overflow: hidden;
    max-height: 30rem;
    margin-bottom: 1.5rem;

    ${({ theme }) => theme.layoutBreakpoints.medium} {
        aspect-ratio: 4/3;
        margin-bottom: 2rem;
    }
`;

const MealPicture = styled(TemplatedLazyImage)`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    background-color: ${({ theme }) => `${theme.palette.backgroundSecondary}`};
`;

const View = ({ meal }) => {
    const { mainImage, name } = meal;
    return (
        <>
            {mainImage && (<Wrapper>
                <MealPicture
                    templatedUrl={mainImage.templatedUrl}
                    size="1280x720"
                    options="fit=cover"
                    alt={name}
                    title={name}
                />
            </Wrapper>)}
        </>
    );
};

export default View;
