import { hot } from 'react-hot-loader';
import styled from 'styled-components';
export { default as fetchData } from './fetchData';
import React from 'react';
import MetaData from './components/meta-data';
import Hero from './components/hero';
import MealInfo from './components/meal-info';
import RestaurantInfo from './components/restaurant-info';
import Map from './components/map';
import Delivery from './components/delivery';
import ContactDetails from './components/contact-details';
import OpenHours from './components/open-hours';
import { spacingHelper } from '../../@nitty'

const ScreenContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${spacingHelper("0 medium")};
    max-width: calc(71.375rem + ${spacingHelper("medium")});


    ${({ theme }) => theme.layoutBreakpoints.medium} {
        padding: ${spacingHelper("0 extraLarge")};
        margin: ${spacingHelper('0 auto')};
        padding-bottom: 5rem;
    }
`

const HeroContainer = styled.div`
    padding: ${spacingHelper("0 medium")};
    margin-top: 1rem;
    max-width: calc(71.375rem + ${spacingHelper("medium")});

    ${({ theme }) => theme.layoutBreakpoints.medium} {
        padding: ${spacingHelper("0 extraLarge")};
        margin: ${spacingHelper('0 auto')};
        margin-top: 2rem;
    }
`

const RowWrapper = styled.div`
    display: flex;
    flex-direction: column;

    ${({ theme }) => theme.layoutBreakpoints.large} {
        flex-direction: row;
        > div {
            flex: 1;
            &:first-child {
                flex: 2;
                margin-right: 2rem;
            }
        }
    }
`

const View = ({ data, location }) => {
    return (
        <>
            <MetaData data={data} />
            <div>
                <HeroContainer>
                    <Hero data={data} />
                </HeroContainer>
                <ScreenContainer>
                    <MealInfo data={data} />
                    <RowWrapper>
                        <div>
                            <Map data={data} />
                            <RestaurantInfo data={data} />
                            <Delivery data={data} />
                        </div>
                        <div>
                            <ContactDetails data={data} />
                            <OpenHours data={data} />
                        </div>
                    </RowWrapper>
                </ScreenContainer>
            </div>
        </>
    );
};

export default hot(module)(View);
