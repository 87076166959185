import React from 'react';
import styled from 'styled-components';
import { H2, Text, typographyHelper, paletteHelper } from '../../../../@nitty'
import Link from '../../../../components/link';

const View = ({name, path, address}) => {
    const { display } = address;
  return (
    <Wrapper>
      <RestaurantLink to={path}>
        <MealName>{name}</MealName>
      </RestaurantLink>
      <div>{display}</div>
    </Wrapper>
  );
};

export default View;

const Wrapper = styled.div`
    margin-bottom: 1.5rem;
    position: relative;

    ${({ theme }) => theme.layoutBreakpoints.medium} {
        margin-bottom: 2rem;
    }
`;

const MealName = styled(H2)`
    color: ${paletteHelper("textPrimary")};
`;

const RestaurantLink = styled(Link)`
    display: block;
    text-decoration: none;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        border-radius: 0.75rem;
    }
`;