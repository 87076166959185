const adaptData = ({ meal: { restaurant } }) => {
    const { name, path, address } = restaurant;

    return {
        name,
        path,
        address
    }
};

export default adaptData;
