import React from 'react';
import styled from 'styled-components';
import { H1 } from '../../../../@nitty'

const Wrapper = styled.div`
    margin-bottom: 1.5rem;

    ${({ theme }) => theme.layoutBreakpoints.medium} {
        margin-bottom: 2rem;
    }
`;

const MealName = styled(H1)`
    font-size: 1.5rem;

    ${({ theme }) => theme.layoutBreakpoints.medium} {
        font-size: 2rem;
    }
`;

const MealPrice = styled.div``;

const View = ({ name, price }) => {
  return (
    <Wrapper>
      <MealName>{name}</MealName>
      <MealPrice>{price}</MealPrice>
    </Wrapper>
  );
};

export default View;
