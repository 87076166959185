import React from 'react';
import styled from 'styled-components';
import { H3, Strong, Text } from '../../../../@nitty'

const Wrapper = styled.div`
    margin-bottom: 1rem;

    ${({ theme }) => theme.layoutBreakpoints.medium} {
        margin-bottom: 1.5rem;
    }
`;

const SectionTitle = styled(H3)`
    margin-bottom: 1rem;
`;

const OpenHour = styled.div`
    margin-bottom: 0.5rem;
`;

const renderOpenHours = (openHours) => {
    if(!openHours) return <div>No open hours</div>

    return openHours.map((item, index) => {
        const { day, time } = item;
        return (
           <OpenHour key={index}>
                <Strong>{day}: </Strong> {time}
            </OpenHour>
        );
    });
};

const View = ({ openHours }) => {
  return (
    <Wrapper>
        <SectionTitle>Open Hours</SectionTitle>
        { renderOpenHours(openHours) }
    </Wrapper>
  );
};

export default View;
