import React from 'react';
import useSearch from '../../../../util/search/useSearch';
import View from '../../../../components/meta-data/View';
import adaptData from './adaptData';

const MetaData = (props) => {
    const { searchQuery } = useSearch();

    return <View {...adaptData(props, searchQuery)} {...props} />
};

export default MetaData;
