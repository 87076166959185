import {
    generateTitle,
    generateDescription,
    generateCanonical,
    generateOpenGraphTitle,
    generateOpenGraphDescription,
    generateOpenGraphImage,
    openGraphImageWidth,
    openGraphImageHeight,
    generateOpenGraphUrl,
    openGraphSiteName,
    generateAppleSmartBanner,
    generateGoogleSmartBanner,
    socialMediaData
} from './utils';

const adaptData = ({ data, searchQuery }) => {
    const { meal } = data;
    const { path } = meal;

    return {
        title: generateTitle(meal),
        meta: [
            generateDescription(meal),
            generateOpenGraphTitle(meal),
            generateOpenGraphDescription(meal),
            generateOpenGraphImage(meal),
            openGraphImageWidth,
            openGraphImageHeight,
            generateOpenGraphUrl(path),
            openGraphSiteName,
            //generateAppleSmartBanner(path),
            //generateGoogleSmartBanner(),
            ...socialMediaData
        ],
        link: [
            generateCanonical(path)
        ]
    }
}

export default adaptData;
