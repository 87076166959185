const adaptData = ({ meal }) => {
    const { name, price } = meal;
    const { display } = price;

    return {
        name,
        price: display
    }
};

export default adaptData;
