import React from 'react';
import styled from 'styled-components';
import { H2 } from '../../../../@nitty'

const Wrapper = styled.div`
    border-radius: 0.75rem;
    overflow: hidden;
    margin-bottom: 1.5rem;

    ${({ theme }) => theme.layoutBreakpoints.medium} {
        margin-bottom: 2rem;
    }
`;

const MapImage = styled.img`
    display: block;
    width: 100%;
    object-fit: cover;
    object-position: center center;
`;

const apiKey = 'AIzaSyDbEu7KV_mqMndyFiFwAx4Y57cexN6xynM';
const base = 'https://maps.googleapis.com/maps/api/staticmap';
const baseUrl = 'https://maps.google.com/maps';
const zoom = 15;
const size = `708x250`;

const View = ({ address }) => {
    const { location } = address;
    const {latitude, longitude} = location;
    const marker = `${latitude},${longitude}`;
    const uri = `${base}?zoom=${zoom}&size=${size}&markers=${marker}&key=${apiKey}`;
    const url = `${baseUrl}?output=search&q=${encodeURI(address.display)}`;

    return (
        <Wrapper>
            <a href={url} target="_blank">
                <MapImage src={uri} />
            </a>
        </Wrapper>
    );
};

export default View;
