import { hot } from 'react-hot-loader';
import React from 'react';
import View from './View';

export { default as fetchData } from './fetchData';

const MealDetailsView = ({ data, location }) => {
  return <View data={data} location={location} />
};

export const MealDetails = hot(module)(MealDetailsView);
