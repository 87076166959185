import React from 'react';
import styled from 'styled-components';
import { Inline, Strong, H3, Text } from '../../../../@nitty'

const Wrapper = styled.div`
    margin-bottom: 1rem;

    ${({ theme }) => theme.layoutBreakpoints.medium} {
        margin-bottom: 1.5rem;
    }
`;

const SectionTitle = styled(H3)`
    margin-bottom: 1rem;
`;

const View = ({ phone }) => {
  return (
    <Wrapper>
        <SectionTitle>Contact Details</SectionTitle>
        <Inline grow={false} gap="small">
            <Text>Phone: </Text> <a href={`tel:${phone}`}>{ phone }</a>
        </Inline>
    </Wrapper>
  );
};

export default View;
