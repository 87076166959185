import { SITE_NAME } from '../../../../../../config/environment';
import {
    generateTitle as defaultGenerateTitle,
    generateDescription as defaultGenerateDescription,
    generateOpenGraphTitle as defaultGenerateOpenGraphTitle,
    generateOpenGraphDescription as defaultGenerateOpenGraphDescription, 
    generateOpenGraphImage as defaultGenerateOpenGraphImage,
} from '../../../../components/meta-data/utils';

export {
    openGraphImageHeight,
    openGraphImageWidth,
    openGraphSiteName,
    socialMediaData,
    generateGoogleSmartBanner,
    generateAppleSmartBanner,
    generateCanonical,
    generateOpenGraphUrl
} from '../../../../components/meta-data/utils';

export const generateTitle = ({ name, restaurant }) => {
    const { name: restaurantName } = restaurant;
    const title = `${name} - ${restaurantName} - ${SITE_NAME}`;

    return defaultGenerateTitle(title);
};

export const generateDescription = ({ name, restaurant }) => {
    const description = `Meal ${name} from ${restaurant.name}, ${restaurant.address.display}`;

    return defaultGenerateDescription(description);
};

export const generateOpenGraphTitle = ({ name }) => {
    const title = `${name} - ${SITE_NAME}`;

    return defaultGenerateOpenGraphTitle(title);
};

export const generateOpenGraphDescription = ({ name, restaurant }) => {
    const description = `Meal ${name} from ${restaurant.name}, ${restaurant.address.display}`;

    return defaultGenerateOpenGraphDescription(description);
};

export const generateOpenGraphImage = ({ mainImage }) => {
    const image = mainImage?.templatedUrl.replace('{size}', '800x600-fit=cover');

    return image ? defaultGenerateOpenGraphImage(image) : null;
}